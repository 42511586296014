// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBWhESvvJYsj6YtaHccb2ipm5zBv9KYLL8",
    authDomain: "cloudtoolsdev.firebaseapp.com",
    projectId: "cloudtoolsdev",
    storageBucket: "cloudtoolsdev.appspot.com",
    messagingSenderId: "830688153207",
    appId: "1:830688153207:web:826d80f886b65fcfa44072",
    measurementId: "G-N35659ETNB"
  },
  adsense: {
    adClient: 'ca-pub-5368296956805437',
    adtest: 'on',
  },
  debug: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
